import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'
import styled from 'styled-components'

const SocialMediaIcons = () => {
  const data = useStaticQuery(graphql`
    query SocialMediasQuery {
      sanityCompanyInfo {
        socialMediaLinks {
          facebook
          instagram
          youtube
        }
      }
    }
  `)

  const socialMediaLinks = data.sanityCompanyInfo.socialMediaLinks

  return (
    <StyledSocialMediaIconLinks>
      <a href={socialMediaLinks.facebook} title='Facebook' aria-label='Facebook' target='_blank' rel='noreferrer'>
        <FaFacebook />
      </a>
      <a href={socialMediaLinks.instagram} title='Instagram' aria-label='Instagram' target='_blank' rel='noreferrer'>
        <FaInstagram />
      </a>
      <a href={socialMediaLinks.youtube} title='YouTube' aria-label='YouTube' target='_blank' rel='noreferrer'>
        <FaYoutube />
      </a>
    </StyledSocialMediaIconLinks>
  )
}

const StyledSocialMediaIconLinks = styled.div`
  color: var(--primary-color);
  /* margin-top: 1.5rem; */
  font-size: 2rem;

  svg {
    margin-right: 1.5rem;
  }
`

export default SocialMediaIcons
