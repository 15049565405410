import React from 'react'
import styled from 'styled-components'
import SocialMediaIcons from '../components/SocialMediaIcons'
import featuredImage from '../images/thank-you-contact-image.png'

const ThankYouContactPage = () => {
  return (
    <StyledThankYouContactPage>
      <img src={featuredImage} alt='' />
      <p>
        <strong>Thank you for contacting us! A member of our team will be in touch shortly.</strong>
      </p>
      <p>
        Don’t forget to find us on Facebook, Instagram or YouTube too!
      </p>
      <SocialMediaIcons />
    </StyledThankYouContactPage>
  )
}

const StyledThankYouContactPage = styled.main`
  display: flow-root;
  max-width: 1500px;
  margin: auto;
  padding: 6rem 1rem 4rem 1rem;

  img {
    /* display: inline; */
    float: left;
    margin: 0 2rem 1rem 0;
    max-width: 100%;
  }

  p {
    margin-bottom: 2rem;
  }

  @media (max-width: 480px) {
    padding: 2rem 1rem;
  }
`

export default ThankYouContactPage
